import * as React from "react";
import constants from "constants/index";
import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";
import { Link } from "gatsby-plugin-react-i18next";
import Styled from "styled-components/macro";
import Meta from "../../../components/meta";
import Layout from "../../../components/layout/Layout";
import Content from "../../../components/layout/Content/Content";
import Button from "../../../components/buttons/Button";
import Headline from "../../../components/texts/Headline";
import Description from "../../../components/texts/Description";
import MoreTemplatesSection from "../../../components/pages/templates/MoreTemplatesSection";

const ContentExtended = Styled(Content)`
    margin-bottom: 10rem;
    
    ${Media.greaterThan("900px")`
        margin-bottom: 12rem;
    `}
`;

const Columns = Styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    
    ${Media.greaterThan("900px")`
        flex-direction: row;
    `}
`;

const ColumnText = Styled.div`
  width: 100%;
    
  ${Media.greaterThan("900px")`
    width: ${(props) => props.widthPercentage}%;
  `}
`;

const HeadlineExtended = Styled(Headline)`
  margin-top: 1rem;
  font-size: 3.5rem;

  ${Media.lessThan("medium")`
    font-size: 2.5rem;
  `}
`;

const ContentH2 = Styled.h2`
  font-size: 3rem;
  line-height: 3.6rem;
  font-weight: 600;
  color: inherit;
  letter-spacing: -0.3px;
  margin: 0 0 1.5rem 0;

  ${Media.greaterThan("large")`
    font-size: 3.5rem;
    line-height: 4rem;
    margin: 0 0 3rem 0;
  `}
`;

const ContentH3 = Styled.h3`
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 600;
  color: inherit;
  letter-spacing: -0.3px;
  margin: 0 0 1.5rem 0;

  ${Media.greaterThan("large")`
    font-size: 2.2rem;
    line-height: 3rem;
    margin: 0 0 3rem 0;
  `}
`;

const ContentH4 = Styled.h4`
  font-size: 3rem;
  line-height: 3.6rem;
  font-weight: 600;
  color: inherit;
  letter-spacing: -0.3px;
  margin: 0 0 1.5rem 0;

  ${Media.greaterThan("large")`
    font-size: 3.5rem;
    line-height: 4rem;
    margin: 0 0 3rem 0;
  `}
`;

const DescriptionExtended = Styled(Description)`
  font-size: 2rem;
  margin: 3rem 0;
`;

const PageListItem = Styled.li`
  font-size: 2rem;
`;

const BreadcrumbContainer = Styled.div`
  margin-top: 10rem;

  ${Media.greaterThan("medium")`
    margin-top: 16rem;
  `}
`;

const BreadcrumbLink = Styled(Link)`
  font-size: 1.5rem;

  &:hover {
    color: ${(props) => props.theme.colors.black};
    text-decoration: underline;
  }
`;

const Breadcrumb = Styled.span`
  font-size: 1.5rem;
`;

const Chevron = Styled.span`
  position: relative;
  margin: 0 0.5rem;
  top: 0.35rem;
`;

const ImagePrimary = Styled(Image)`
  display: block;
  border-radius: 0.8rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.11);
`;

const ImageSecondary = Styled(Image)`
  display: block;
  width: calc(50% - 1rem);
  margin-bottom: 2rem;
  border-radius: 0.8rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.11);
`;

const Thumbnails = Styled.div`
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 3rem 0;
`;

const NavButtonLink = Styled.a`
`;

const ButtonContainer = Styled.div`
  margin-bottom: 3rem;
`;

const ButtonExtended = Styled(Button)`
  margin: 3rem 3rem 0 0;
`;

const PreviewHeader = Styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  margin-top: 6rem;
`;

const ButtonExtendedPreview = Styled(ButtonExtended)`
  margin: 0 0 2rem 0;
`;

const ProjectIframe = Styled.iframe`
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: auto;
  display: block;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
`;

const ContentLink = Styled.a`
  font-weight: bold;
`;

const FAQSection = Styled.div`
  margin: 9rem 0;
`;

const Questions = Styled.div`
`;

const QuestionContainer = Styled.details`
    border-top: 1px solid #eeeeee;
`;

const QuestionLabel = Styled.summary`
    font-size: 3rem;
    line-height: 3.6rem;
    font-weight: 600;
    letter-spacing: -0.3px;
    margin: 2rem 0 2rem 0;
    cursor: pointer;
`;

const Answer = Styled(Description)`
  margin-bottom: 3rem;
`;

type TemplatePageProps = {
  title: string;
  canonical?: string;
  category: string;
  url: string;
  metaDescription: string;
  description: string;
  pages: Array<String>;
  projectCode: string;
  img1: GatsbyImageProps;
  img1alt: string;
  img2: GatsbyImageProps;
  img2alt: string;
  img3: GatsbyImageProps;
  img3alt: string;
  img4: GatsbyImageProps;
  img4alt: string;
  img5: GatsbyImageProps;
  img5alt: string;
  faqtitle?: string;
  faqquestions?: Array<{
    question: string;
    answer: string;
  }>;
};

const TemplatePage = ({
  title,
  canonical,
  category,
  url,
  metaDescription,
  description,
  pages,
  projectCode,
  img1,
  img1alt,
  img2,
  img2alt,
  img3,
  img3alt,
  img4,
  img4alt,
  img5,
  img5alt,
  faqtitle,
  faqquestions,
}: TemplatePageProps) => {
  const faqQuestionsObj: any = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [],
  };

  if (faqquestions != undefined) {
    for (var i = 0; i < faqquestions.length; i++) {
      const questionObj: {
        "@type": string;
        name: string;
        acceptedAnswer: { "@type": string; text: string };
      } = {
        "@type": "Question",
        name: faqquestions[i]["question"],
        acceptedAnswer: {
          "@type": "Answer",
          text: faqquestions[i]["answer"],
        },
      };
      faqQuestionsObj["mainEntity"].push(questionObj);
    }
  }

  return (
    <Layout>
      <Meta
        title={title}
        description={metaDescription}
        url={url}
        canonical={canonical != undefined ? canonical : false}
        faqschema={
          faqquestions != undefined ? JSON.stringify(faqQuestionsObj) : false
        }
      />

      <ContentExtended narrow>
        <BreadcrumbContainer>
          <BreadcrumbLink to="/templates/">Templates</BreadcrumbLink>
          <Chevron aria-hidden="true">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
            >
              <path d="M6.47 4.29l3.54 3.53c.1.1.1.26 0 .36L6.47 11.7a.75.75 0 1 0 1.06 1.06l3.54-3.53c.68-.69.68-1.8 0-2.48L7.53 3.23a.75.75 0 0 0-1.06 1.06z"></path>
            </svg>
          </Chevron>
          <BreadcrumbLink
            to={
              "/templates/" + category.toLowerCase().split(" ").join("-") + "/"
            }
          >
            {category}
          </BreadcrumbLink>
          <Chevron aria-hidden="true">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
            >
              <path d="M6.47 4.29l3.54 3.53c.1.1.1.26 0 .36L6.47 11.7a.75.75 0 1 0 1.06 1.06l3.54-3.53c.68-.69.68-1.8 0-2.48L7.53 3.23a.75.75 0 0 0-1.06 1.06z"></path>
            </svg>
          </Chevron>
          <Breadcrumb>
            {title.substring(
              0,
              title.indexOf("|") != -1 ? title.indexOf("|") - 1 : title.length
            )}
          </Breadcrumb>
        </BreadcrumbContainer>
        <Columns>
          <ColumnText widthPercentage="75">
            <HeadlineExtended as="h1">
              {title.substring(
                0,
                title.indexOf("|") != -1 ? title.indexOf("|") - 1 : title.length
              )}
            </HeadlineExtended>

            <ImagePrimary
              fluid={"fluid" in img1 ? img1.fluid : null}
              alt={img1alt}
            />

            <ButtonContainer>
              <NavButtonLink
                href={`https://${constants.APP_DOMAIN}/templates/${projectCode}`}
                target="_blank"
              >
                <ButtonExtended variant="primarylight" solid>
                  Preview
                </ButtonExtended>
              </NavButtonLink>

              <NavButtonLink
                href={`https://${constants.APP_DOMAIN}/use-template?tid=${projectCode}`}
              >
                <ButtonExtended variant="primarylight" solid>
                  Use this template
                </ButtonExtended>
              </NavButtonLink>
            </ButtonContainer>

            {description.split("<br/>").map((line) => {
              if (line.indexOf("h3:") != -1) {
                return <ContentH3>{line.substring(4)}</ContentH3>;
              }
              if (line.indexOf("h2:") != -1) {
                return <ContentH2>{line.substring(4)}</ContentH2>;
              }
              if (line.indexOf("<a:") != -1) {
                const href = line.substring(
                  line.indexOf("<a:") + 3,
                  line.indexOf(">")
                );
                const text = line.substring(
                  line.indexOf(">") + 1,
                  line.indexOf("</a>")
                );
                return (
                  <DescriptionExtended>
                    {line.substring(0, line.indexOf("<a:"))}
                    <ContentLink href={href}>{text}</ContentLink>
                    {line.substring(line.indexOf("</a>") + 4)}
                  </DescriptionExtended>
                );
              }
              return <DescriptionExtended>{line}</DescriptionExtended>;
            })}

            <ContentH2>What pages are included?</ContentH2>
            <ul>
              {pages.map((page) => {
                return <PageListItem>{page}</PageListItem>;
              })}
            </ul>

            <Thumbnails>
              <ImageSecondary
                fluid={"fluid" in img2 ? img2.fluid : null}
                alt={img2alt}
              />
              <ImageSecondary
                fluid={"fluid" in img3 ? img3.fluid : null}
                alt={img3alt}
              />
              <ImageSecondary
                fluid={"fluid" in img4 ? img4.fluid : null}
                alt={img4alt}
              />
              <ImageSecondary
                fluid={"fluid" in img5 ? img5.fluid : null}
                alt={img5alt}
              />
            </Thumbnails>

            <PreviewHeader>
              <ContentH4>Interactive Preview</ContentH4>
              <NavButtonLink
                href={`https://${constants.APP_DOMAIN}/templates/${projectCode}`}
              >
                <ButtonExtendedPreview variant="primarylight" solid>
                  Full screen
                </ButtonExtendedPreview>
              </NavButtonLink>
            </PreviewHeader>

            <ProjectIframe
              height={category.indexOf("Mobile") == 0 ? "687" : "520"}
              src={`https://${constants.APP_DOMAIN}/templates/${projectCode}/embed`}
              allowfullscreen
            ></ProjectIframe>
          </ColumnText>
          <MoreTemplatesSection />
        </Columns>

        {faqtitle && faqquestions && (
          <FAQSection>
            <HeadlineExtended as="h2">{faqtitle}</HeadlineExtended>
            <Questions>
              {faqquestions.map((question) => (
                <QuestionContainer>
                  <QuestionLabel>{question.question}</QuestionLabel>
                  <Answer>{question.answer}</Answer>
                </QuestionContainer>
              ))}
            </Questions>
          </FAQSection>
        )}
      </ContentExtended>
    </Layout>
  );
};

export default TemplatePage;

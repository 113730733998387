import * as React from "react";
import Media from "@uizard-io/styled-media-query";
import { Link } from "gatsby-plugin-react-i18next";
import Styled from "styled-components/macro";

import Headline from "../../../components/texts/Headline";

// Mobile
import mobileWireframeImage from "../home/HomeExamples/mobile_wireframe_cover.png";
import productivityAppImage from "../home/HomeExamples/productivity-app-cover.png";
import travelAppImage from "../home/HomeExamples/travel_app_cover.png";
import basketImage from "../home/HomeExamples/basket_cover.png";
import bikeImage from "../home/HomeExamples/bike_rental_cover.png";
import bookImage from "../home/HomeExamples/book-app-cover.png";
import budgetImage from "../home/HomeExamples/finance-app-cover.png";
import carsharingImage from "../home/HomeExamples/car-sharing-app-cover.png";
import cookingImage from "../home/HomeExamples/cooking-app-cover.png";
import datingImage from "../home/HomeExamples/dating-app-cover.png";
import eventbookingImage from "../home/HomeExamples/ticket-booking-app-cover.png";
import fitnessImage from "../home/HomeExamples/workout-app-cover.png";
import flightImage from "../home/HomeExamples/flight-booking-app-cover.png";
import groceryImage from "../home/HomeExamples/grocery-app-cover.png";
import habitImage from "../home/HomeExamples/habit-app-cover.png";
import healthtrackerImage from "../home/HomeExamples/health-tracker-app-cover.png";
import learningImage from "../home/HomeExamples/learning-app-cover.png";
import notesImage from "../home/HomeExamples/notes-app-cover.png";
import podcastImage from "../home/HomeExamples/podcast-app-cover.png";
import medicalImage from "../home/HomeExamples/medical-app-cover.png";
import meditationImage from "../home/HomeExamples/meditation-app-cover.png";
import mobileuiImage from "../home/HomeExamples/mobile-ui-kit-cover.png";
import moneytransferImage from "../home/HomeExamples/money-transfer-app-cover.png";
import materialYouImage from "../home/HomeExamples/Google_Material_You_cover.png";
import gmdeaImage from "../home/HomeExamples/GMDEA_cover.png";
import newsfeedImage from "../home/HomeExamples/news-app-cover.png";
import todolistImage from "../home/HomeExamples/to-do-app-cover.png";
import iOSImage from "../home/HomeExamples/ios15_cover.png";
import messagingAppImage from "../home/HomeExamples/messaging_app_cover.png";
import sportAppImage from "../home/HomeExamples/sports_mobile_app_cover.png";
import socialmediaImage from "../home/HomeExamples/social-media-app-cover.png";
import tablebookingImage from "../home/HomeExamples/table-booking-app-cover.png";
import transportImage from "../home/HomeExamples/transport-app-cover.png";
import quickBiteMobileImage from "../home/HomeExamples/food_delivery_mobile_app_cover.png";
import musicMobileAppImage from "../home/HomeExamples/music_mobile_app_cover.png";
import cryptoMobileAppImage from "../home/HomeExamples/crypto_nft_mobile_app_cover.png";

// Tablet
import bankAppTabletImage from "../home/HomeExamples/bank-tablet-app-cover.png";
import booktabImage from "../home/HomeExamples/book-tab-app-cover.png";
import cloudstorageImage from "../home/HomeExamples/cloud-storage-tab-app-cover.png";
import cookingtabImage from "../home/HomeExamples/cooking-tab-app-cover.png";
import learningtabImage from "../home/HomeExamples/learning-tab-app-cover.png";
import moneytransfertabImage from "../home/HomeExamples/money-transfer-tab-app-cover.png";
import musicstreamingtabImage from "../home/HomeExamples/music-tab-app-cover.png";
import newsfeedtabImage from "../home/HomeExamples/news-tab-app-cover.png";
import notestabImage from "../home/HomeExamples/notes-tab-app-cover.png";
import todotabImage from "../home/HomeExamples/to-do-tab-app-cover.png";
import eCommerceTabletImage from "../home/HomeExamples/e-commerce-tablet-app-cover.png";
import GMDTabletImage from "../home/HomeExamples/GMD-tablet-app-cover.png";
import landoTabletImage from "../home/HomeExamples/lando-tablet-cover.png";

// Web App
import gmdwaImage from "../home/HomeExamples/GMDWA_cover.png";
import carbonImage from "../home/HomeExamples/IBM_Carbon_cover.png";
import quickBiteImage from "../home/HomeExamples/food-delivery-web-app-cover.png";
import cloudwebImage from "../home/HomeExamples/cloud-sharing-web-app-cover.png";
import ecomdashImage from "../home/HomeExamples/ecommerce-dashboards-context.png";
import todowebImage from "../home/HomeExamples/to-do-web-app-cover.png";
import videocallImage from "../home/HomeExamples/video-call-web-app-cover.png";
import projectwebImage from "../home/HomeExamples/project-management-web-app-cover.png";
import streamingwebImage from "../home/HomeExamples/music-streaming-web-app-cover.png";
import hrAppImage from "../home/HomeExamples/hr_app_cover.png";
import pitchDeckImage from "../home/HomeExamples/online_interactive_pitch_deck_cover.png";
import onlineBankingImage from "../home/HomeExamples/online_banking_web_app_cover.png";

// Website
import eShopImage from "../home/HomeExamples/e_shop_cover.png";
import landoImage from "../home/HomeExamples/lando_cover.png";
import webWireframeImage from "../home/HomeExamples/web_wireframe_cover.png";
import estateImage from "../home/HomeExamples/estate_cover.png";
import webuikitImage from "../home/HomeExamples/web-ui-kit-cover.png";
import embarkImage from "../home/HomeExamples/embark_cover.png";
import web3Image from "../home/HomeExamples/web3_crypto_website_cover.png";
import petShopImage from "../home/HomeExamples/pet_web_store_cover.png";

// Components
import blogImage from "./componentTemplates/blog.png";
import cardImage from "./componentTemplates/card.png";
import dialogImage from "./componentTemplates/dialog.png";
import footerImage from "./componentTemplates/footer.png";
import formImage from "./componentTemplates/form.png";
import galleryImage from "./componentTemplates/gallery.png";
import headerImage from "./componentTemplates/header.png";
import listImage from "./componentTemplates/list.png";
import multimediaImage from "./componentTemplates/multimedia.png";
import navImage from "./componentTemplates/nav.png";
import panelImage from "./componentTemplates/panel.png";
import shopImage from "./componentTemplates/shop.png";
import socialImage from "./componentTemplates/social.png";
import tableImage from "./componentTemplates/table.png";

const ColumnText = Styled.div`
  width: 100%;
    
  ${Media.greaterThan("900px")`
    width: ${(props) => props.widthPercentage}%;
  `}
`;

const HeadlineExtendedSmall = Styled(Headline)`
  font-size: 2.5rem;

  ${Media.lessThan("medium")`
    font-size: 2rem;
    margin-top: 6rem;
  `}
`;

const Example = Styled.div`
text-align: center;

${Media.lessThan("medium")`
  width: 75%;
  margin: auto;
`}
`;

const LinkExtended = Styled(Link)`
&:hover, &:focus {
  color: ${(props) => props.theme.colors.black};
  cursor: pointer;
}
`;

const HTMLImage = Styled.img`
display: block;
width: 100%;
`;

const ImageContainer = Styled.div`
position: relative;
border-radius: 5px;
overflow: hidden;
box-shadow: 0 0 20px rgba(0, 0, 0, 0.11);

&:after {
  content: 'Learn More';
  font-weight: bold;
  color: ${(props) => props.theme.colors.white};
  padding-top: 25%;
  align-content: center;
  font-size: 2rem;
  position: absolute;
  width: 100%;
  height:100%;
  top:0;
  left:0;
  background: rgba(0,0,0,0.45);
  opacity: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

&:hover:after {
  opacity: 1;
}
`;

const ExampleTitle = Styled(Headline)`
font-size: 1.5rem;
line-height: 3rem;
`;

const MoreTemplatesSections = () => {
  const thumbnails: { image: any; alt: string; title: string; link: string }[] =
    [
      {
        image: quickBiteImage,
        alt: "Food delivery web app project image",
        title: "Food delivery web app",
        link: "/templates/web-app-templates/online-food-delivery/",
      },
      {
        image: onlineBankingImage,
        alt: "Online banking web app project image",
        title: "Online banking web app",
        link: "/templates/web-app-templates/online-banking-web-app/",
      },
      {
        image: mobileWireframeImage,
        alt: "mobile wireframe example project image",
        title: "Mobile app wireframe",
        link: "/templates/mobile-app-templates/mobile-app-wireframe/",
      },
      {
        image: web3Image,
        alt: "Web3 crypto website project image",
        title: "Web3 crypto website",
        link: "/templates/website-templates/web3-crypto-website/",
      },
      {
        image: sportAppImage,
        alt: "sport mobile app design template",
        title: "Sport mobile app",
        link: "/templates/mobile-app-templates/sports-app/",
      },
      {
        image: webWireframeImage,
        alt: "Website wireframe example project image",
        title: "Website wireframe",
        link: "/templates/website-templates/website-wireframe/",
      },
    ];

  return (
    <ColumnText widthPercentage="20">
      <HeadlineExtendedSmall>More templates</HeadlineExtendedSmall>

      {thumbnails.map((example) => (
        <Example>
          <LinkExtended to={example.link}>
            <ImageContainer>
              <HTMLImage src={example.image} alt={example.alt} />
            </ImageContainer>
            <ExampleTitle>{example.title}</ExampleTitle>
          </LinkExtended>
        </Example>
      ))}
    </ColumnText>
  );
};

export default MoreTemplatesSections;
